:root {
    --primary: #75AA17;
    --secondary: #f6f6f6;
    --delete: #c4806e;
    --p-secondary: #7c7c7c;
    --custom-border: 2px solid var(--secondary);
    --custom-border-radius: 0px;
    --custom-border-shadow: 0px 2px 4px rgba(0, 0, 0, 0.205);
    --custom-font-family: "Calibri", sans-serif;
    --tenerife: #35709e;
    --laspalmas: #db8f22;



    /** Calendar Control buttons **/
    --fc-button-bg-color: transparent;
    --fc-button-border-color: transparent;
    --fc-button-text-color: black;

    --fc-button-hover-bg-color: transparent;
    --fc-button-hover-border-color: transparent;

    --fc-button-active-bg-color: transparent;
    --fc-button-active-border-color: transparent;
    --fc-today-bg-color: transparent;

    --fc-event-bg-color: var(--primary);
    --fc-event-border-color: var(--primary);
    ;
    --fc-event-text-color: black;

}

/** Colores de estado input **/
.rojo {
    border: 2px solid rgb(169, 72, 47) !important;
}

.verde {
    border: 2px solid var(--primary) !important;
}

body {
    margin: 0 !important;
}


/** Control buttons **/

.fc-today-button.fc-button.fc-button-primary {
    font-family: var(--custom-font-family);
    font-weight: bold;
    color: black;
    border: none;
}

.fc-today-button.fc-button.fc-button-primary:disabled {
    color: var(--p-secondary);
    opacity: 1;
    border: none;
}

.fc-button {
    box-shadow: none !important;
}

.fc-button:focus {
    box-shadow: none !important;
}

.fc-toolbar-chunk {
    display: flex;
    flex-direction: row;
}

/*

.fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: 100% !important;
    height: 100% !important;
}

.fc-scrollgrid-sync-table {
    width: 100% !important;
    height: 100% !important;
}

.fc-scrollgrid-sync-table {
    width: 100% !important;
    height: 100% !important;
}

.fc-col-header {
    width: 100% !important;
}

*/

/**Alerts **/

.swal2-popup {

    border-radius: 0px !important;
}

.swal2-loading {
    align-items: center !important;
    justify-content: center !important;

}

div:where(.swal2-loader) {
    border-color: var(--primary) rgba(0, 0, 0, 0) var(--primary) rgba(0, 0, 0, 0) !important;
}


.swal2-icon {}

.swal2-success {
    border: none !important;
    font-family: var(--custom-font-family);
}

.swal2-input {
    display: flex;
    flex-direction: row;
    background: var(--secondary);
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 0px 1px #ececec;
    border: 1px solid rgb(209, 209, 209);
    height: 40px !important;
    text-indent: 10px;
    border: 2px solid var(--primary);
    border-radius: 0px;
    padding: 10px !important;
    margin: 0 !important;
    font-family: var(--custom-font-family);
}

.swal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: var(--custom-font-family);
}

.swal2-input:focus {
    box-shadow: 0 0 5px var(--primary);
    border: 1px solid var(--primary);
    outline: none;
    font-family: var(--custom-font-family);
}


.swal2-success-line-tip {
    background-color: var(--primary) !important;
}

.swal2-success-line-long {
    background-color: var(--primary) !important;
}


.swal2-info {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
    font-family: var(--custom-font-family);
}

.swal2-title {
    font-size: 20px !important;
    font-weight: normal !important;
    font-family: var(--custom-font-family) !important;
    color: var(--primary);
    width: 100%;
    padding: 0;
}

.swal2-html-container {
    font-size: 22px !important;
    font-weight: bold !important;
    margin: 20px 0 0 0 !important;
    display: flex;
    font-family: var(--custom-font-family) !important;
}

.swal2-actions {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: end;
    justify-content: end;
    gap: 20px;
    font-family: var(--custom-font-family);
}

.swal2-deny,
.swal2-confirm {
    color: black !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 120px;
    font-family: var(--custom-font-family);
}

.swal2-confirm {
    background-color: var(--primary) !important;
    font-family: var(--custom-font-family);
}

.swal2-deny {
    background-color: #aaaaaa !important;
    font-family: var(--custom-font-family);
}



/**Default container **/

.aot-main-container {
    background-color: transparent;
    min-height: 90dvh;

}



.aot-content-container {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
}


/** DEFAULT MENU **/

.default-menu {
    display: flex;
    flex-direction: row;
    width: calc(100% - 11px);
    justify-content: center;
    height: 50px;
    background-color: white;
    /*    position: absolute;*/
    align-items: center;
    gap: 50px;
    box-shadow: var(--custom-border-shadow);
    padding-bottom: 3px;

}

.default-menu.top {
    top: 10dvh;
}

.default-menu>a {
    min-width: 50px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.default-menu>a.selected {
    border-bottom: 5px solid var(--primary);
}




/** Fonts **/

p,
h1,
h2,
h3,
h4,
h5,
a,
u,
div,
input,
label,
button,
span,
li,
strong,
input,
input::placeholder,
textarea,
small,
pre,
figcaption {
    font-family: var(--custom-font-family);
}


.footer-link {
    font-size: 14px;
}


p,
span,
strong,
li,
ul,
a {
    font-size: 16px;
}

small {
    font-size: 15px;
    line-height: 25px;
}


p {
    line-height: 25px;
    margin: 0;
}

pre {
    font-size: 20px;
}


h1 {
    font-size: 24px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    font-size: 18px;
    font-weight: normal;
}

a {
    color: black;
    font-weight: normal;
    text-decoration: none;
}

a:hover {
    color: var(--primary);
}


.main-link {
    font-size: 18px;
}

.secondary-link,
label {
    font-size: 17px;
}

input,
select,
textarea {
    font-size: 14px;
}

.aot-download-link {
    cursor: pointer;
    color: #0000EE;
    text-decoration: underline;
    font-weight: normal;
}

/** Inputs **/

.aot-input-search-box {
    background: var(--secondary);
    border: none;
    height: 40px;
    min-width: 350px;
    padding-left: 2%;
}

.aot-input-search-box:focus {
    outline: none;
}

input[type='checkbox'] {
    appearance: none;
    cursor: pointer;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 14px;
    height: 14px;
    appearance: none;
    border: 2px solid #888;
}

input[type='checkbox']:checked {
    background-color: var(--primary);
}


input[type="search"] {
    width: 100%;
    box-sizing: border-box;
    border: none;
    height: 41px;
    background-color: #75aa179e;
    text-indent: 40px;
    background-image: url("./assets/images/lupa.svg");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: left 10px center;
}


input[type="search"]:focus {
    box-shadow: 0 0 5px var(--primary);
    border: 1px solid var(--primary);
    outline: none;
}

input[type="search"]::placeholder {
    color: black;
}

/** Status Colors **/

.finished {
    color: #d19a8d;
    font-weight: bold;
}

.available {
    color: #6190b3;
    font-weight: bold;
}

/**Buttons **/

.custom-btn {
    border: none;
    width: 100%;
    height: 50px;

}


.primary-button {
    background: var(--primary);
    color: black;
    border: none;
    height: 40px;
    width: 70%;
    font-size: 16px;
    box-shadow: var(--custom-border-shadow);
}

.secondary-button {
    background: var(--secondary);
    color: black;
    border: 1px solid rgb(201, 201, 201);
    height: 40px;
    width: 70%;
    font-size: 16px;
    box-shadow: var(--custom-border-shadow);
}

.delete-button {
    background: var(--delete);
    color: black;
    border: 1px solid rgb(201, 201, 201);
    height: 40px;
    width: 70%;
    font-size: 16px;
    box-shadow: var(--custom-border-shadow);
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
}

.disabled:hover {
    cursor: not-allowed;
    text-decoration: none;
}

.disabled_button {
    opacity: 0.8;
}

button,
a,
u {
    cursor: pointer;
}

input[type='text'] {
    text-indent: 10px;
}


/** Status**/

.disabled-link {
    pointer-events: none;
    display: none !important;

}

.onlydesktop {
    display: flex;
}

.onlymobile {
    display: none !important;
}



@media only screen and (max-width:1600px) {


    /** Fonts máximo portátil**/

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    u,
    input,
    label,
    button,
    span,
    li,
    strong,
    input,
    input::placeholder,
    textarea,
    small,
    figcaption {
        font-family: var(--custom-font-family)
    }


    .footer-link {
        font-size: 14px;
    }


    p,
    span,
    strong,
    li,
    ul,
    a {
        font-size: 15px;
    }

    small {
        font-size: 14px;
        line-height: 25px;
    }


    p {
        line-height: 25px;
        margin: 0;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 19px;
    }

    h3 {
        font-size: 17px;
        font-weight: bold;
    }

    h4 {
        font-size: 17px;
        font-weight: normal;
    }

    a {
        color: black;
        font-weight: normal;
        text-decoration: none;
    }

    a:hover {
        color: var(--primary);
    }


    .main-link {
        font-size: 17px;
    }

    .secondary-link,
    label {
        font-size: 16px;
    }

    input,
    select,
    textarea {
        font-size: 14px;
    }


}



/** INPUTS**/
.aot-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.aot-input>input[type='text'],
input[type='email'],
input[type='number'] {
    display: flex;
    flex-direction: row;
    background: var(--secondary);

    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 0px 1px #ececec;
    border: 1px solid rgb(209, 209, 209);
    height: 30px;
    text-indent: 10px;
}

input[type='number']::-webkit-inner-spin-button {
    appearance: textfield;
    -webkit-appearance: none;
}

input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.aot-input>input[type='text-area'],
textarea {
    flex-direction: row;
    background: var(--secondary);
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 0px 1px #ececec;
    border: 1px solid rgb(209, 209, 209);
    height: 100px;
    text-indent: 10px;
}

.aot-input>input[type='text-area'],
textarea {
    flex-direction: row;
    background: var(--secondary);
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 0px 1px #ececec;
    border: 1px solid rgb(209, 209, 209);
    text-indent: 0px;
    padding: 10px
}

.aot-input>input[type='text']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='text-area']:focus,
textarea:focus {
    box-shadow: 0 0 5px var(--primary);
    border: 1px solid var(--primary);
    outline: none;
}


input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #bebebe;
    padding: 10px 20px;
    color: black;
    cursor: pointer;
    height: 40px;
    width: 150px;
}

input[type=file]::file-selector-button:hover {
    background: grey;
}

label {
    font-weight: bold;
}



/**SELECT**/


.aot-select {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

select {
    background: var(--secondary);
    box-shadow: inset 0px 1px 0px 1px #ececec;
    border: none;
    text-indent: 10px;
    border: 1px solid rgb(209, 209, 209);
    height: 35px;
    -webkit-appearance: menulist !important;
    -moz-appearance: menulist !important;
    appearance: menulist !important;
    transform: scaleY(1) !important;
    color: black;
}

select:focus {
    box-shadow: 0 0 5px var(--primary);
    border: 1px solid var(--primary);
    outline: none;
}

select:focus-visible {
    outline: none;

}

select>option {
    color: black;
}

.sinValor {
    color: grey;
}







/** CHECKBOX **/

.aot-input-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aot-input-checkbox>label {
    margin-left: 10px;
}


input[type='checkbox']:after {
    width: 20px;
    height: 20px;
    top: -4px;
    left: -5px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid rgb(209, 209, 209);
    box-shadow: inset 0px 0px 0px 1px #ececec;
}

input[type='checkbox'].rojo:after {
    border: 2px solid rgb(169, 72, 47);
}

input[type='checkbox']:checked:after {
    width: 20px;
    height: 20px;
    top: -3px;
    left: -5px;
    position: relative;
    background-color: var(--primary);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid var(--primary);

}


.aot-input-checkbox>label {
    font-weight: normal;
}



/** CKEDITOR **/
.ck-editor__editable_inline {
    min-height: 150px;
}

@media only screen and (min-width:911px) {
    *::-webkit-scrollbar {
        width: 8px;

    }

    *::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 0px;
    }
}


@media only screen and (max-width: 910px) {
    .onlymobile {
        display: flex;
    }

    .onlydesktop {
        display: none;
    }


    h1 {
        font-size: 25px;
        text-align: center;
    }





}